<template>
  <div>
    <b-form-group label-for="filter-term">
      <b-form-input
        id="filter-term"
        v-model="options.like_term"
        placeholder="Intitulé du coupon"
        autofocus
        @change="updateFilters()"
      />
    </b-form-group>
    <b-form-group label-for="filter-scheme">
      <b-form-select
        id="filter-scheme"
        v-model="options.scheme"
        placeholder="Type de coupon"
        @change="updateFilters()"
      >
        <template #first>
          <b-form-select-option :value="null" disabled>
            -- Filtrer par destination --
          </b-form-select-option>
        </template>
        <b-form-select-option
          v-for="option in ['VOUCHER', 'COUPON']"
          :key="option"
          :value="option"
        >
          {{ $t(`COUPON.${option}`) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <!-- <pre>{{ options }}</pre> -->
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'

export default {
  name: 'CouponsFilters',
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: {
        like_term: null,
      },
    }
  },
  created() {
    this.options = Object.assign(this.options, this.filters)
  },
  methods: {
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.options, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
  },
}
</script>
