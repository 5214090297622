<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <template v-if="creation == true">
          <div class="row">
            <div class="col-lg-6">
              <b-form-group label="Règle" label-for="input-amount_type">
                <b-form-select v-model="form.amount_type">
                  <b-form-select-option
                    v-for="option in ['FIXED_AMOUNT', 'PERCENT']"
                    :key="option"
                    :value="option"
                  >
                    {{ $t(`COUPON.${option}`) }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group label-for="input-amount" :label="amountTitle">
                <validation-provider
                  #default="{ errors }"
                  :name="amountTitle"
                  rules="required"
                >
                  <b-form-input
                    id="input-amount"
                    v-model="form.amount"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="amountTitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <b-form-group label="Type d’usage" label-for="input-usage_scheme">
            <b-form-radio-group
              id="input-usage_scheme"
              v-model="form.usage_scheme"
            >
              <b-form-radio
                v-for="item in ['ONCE', 'ONCE_PER_USER']"
                :key="item"
                :value="item"
                name="input-usage_schemes"
              >
                {{ $t(`COUPON.${item}`) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
        <template v-if="couponId">
          <b-form-group label-for="input-status">
            <b-form-select v-model="form.status">
              <b-form-select-option
                v-for="option in ['ENABLED', 'DISABLED']"
                :key="option"
                :value="option"
              >
                {{ $t(`COUPON.${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </template>
        <template v-if="creation == true">
          <b-form-group
            label-for="input-usage_limit"
            label="Nombre d’utilisation"
            description="Nombre de fois où le coupon s'appliquera"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="py-1">
                  <b-form-checkbox
                    v-model="form.usage_limit"
                    name="checkbox-1"
                    :value="0"
                    :unchecked-value="1"
                  >
                    <b>Illimité</b>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-lg-6">
                <b-form-input
                  v-if="form.usage_limit > 0"
                  id="input-usage_limit"
                  v-model="form.usage_limit"
                  type="number"
                />
              </div>
            </div>
          </b-form-group>
          <b-form-group label="S’applique à">
            <b-form-checkbox-group v-model="form.scopes">
              <b-form-checkbox
                v-for="item in ['ORDER', 'SUBSCRIPTION']"
                :key="item"
                :value="item"
                :disabled="item == 'ORDER' || false"
              >
                {{ $t(`COUPON.${item}`) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </template>
        <template v-if="creation == true || couponId">
          <b-form-group
            label-for="input-public_id"
            label="ID"
            description="Cet ID publique permet à un utilisateur d’utiliser ce bon de réduction. Exemple : BIO20"
          >
            <validation-provider
              #default="{ errors }"
              name="ID"
              rules="required"
            >
              <b-form-input
                id="input-public_id"
                v-model="form.public_id"
                type="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-for="input-label"
            label="Nom"
            description="Ce nom apparaîtra sur le site, les reçus et les factures des clients."
          >
            <b-form-input id="input-label" v-model="form.label" type="text" />
          </b-form-group>
        </template>
        <template v-if="couponId">
          <b-form-group label-for="input-description" label="Notes">
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              type="text"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </template>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right d-none">
            <b-button v-if="couponId" v-b-modal.modal-delete variant="danger">
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-recipe"
      centered
      hide-header-close
      title="Suprimmer la recette"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteUser()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <debug>{{ form }} </debug>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BModal,
} from 'bootstrap-vue'

export default {
  name: 'CouponForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    couponId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      form: {
        status: 'ENABLED',
        scheme: 'COUPON',
        amount_type: 'FIXED_AMOUNT',
        amount: 0,
        usage_scheme: 'ONCE_PER_USER',
        usage_limit: 1,
        public_id: null,
        label: null,
        description: null,
        scopes: ['ORDER'],
      },
    }
  },
  computed: {
    amountTitle() {
      let result
      if (this.form.amount_type === 'FIXED_AMOUNT') {
        result = 'Montant de la réduction en €'
      }
      if (this.form.amount_type === 'PERCENT') {
        result = 'Pourcentage de la réduction'
      }
      return result
    },
  },
  watch: {
    couponId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.couponId) {
        const resp = await this.$http.get(`/coupons/${this.couponId}/`, {
          headers: {
            'X-Fields': `${Object.keys(this.form).join(',')}`,
          },
        })
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.couponId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/coupons/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/coupons/${this.couponId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': 'status,label,public_id,description',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'L’enregistrement a échoué',
              text: 'Probablement un code doublon.',
              icon: 'SaveIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          }
        )
        console.log(e)
      }
    },
    async deleteUser() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(`/coupons/${this.couponId}/`, {
        headers: {
          'X-Fields': 'id',
          'X-Keys': 'id',
          'X-CSRF-TOKEN': respCsrf.data.csrf,
        },
      })
      this.$emit('deleted', resp.data)
      this.$refs['delete-recipe'].hide()
    },
  },
}
</script>
