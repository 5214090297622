<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <coupons-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Créer un coupon</h3>
      <coupon-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Intitulé</th>
          <th class="text-right">Valeur</th>
          <th class="text-right">Code</th>
          <th class="text-right">Le</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="coupon in coupons">
          <tr
            :key="coupon.id"
            @click.prevent="$router.push(`/coupons/${coupon.id}`)"
          >
            <td>
              <h4>
                {{ coupon.label }}
                <br />
                <small>
                  <span
                    class="badge"
                    :class="{
                      'badge-success': coupon.status == 'ENABLED',
                      'badge-danger': coupon.status != 'ENABLED',
                    }"
                  >
                    {{ $t(`COUPON.${coupon.status}`) }}
                  </span>
                  {{ $t(`COUPON.${coupon.usage_scheme}`) }}
                  <span v-if="coupon.scheme == 'VOUCHER'">
                    - Offert par
                    <router-link
                      :to="`/users/${coupon.attributes.sponsor.user_id}`"
                    >
                      {{ coupon.attributes.sponsor.fullname }}
                    </router-link>
                  </span>
                  <br />
                  {{ coupon.description }}
                </small>
              </h4>
            </td>
            <td class="text-right">
              <span class="text-nowrap">
                {{ coupon.amount_formated
                }}{{ $t(`COUPON.${coupon.amount_type}_UNIT`) }}
              </span>
            </td>
            <td class="text-right">
              {{ coupon.public_id }}
            </td>
            <td class="text-right">
              {{ $dayjs(coupon.created_at).format('DD-MM-YYYY') }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <debug>{{ coupons }}</debug>
    <!-- <debug>{{ pagination }}</debug> -->
    <!-- <debug>{{ filters }}</debug> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import CouponForm from '@/components/CouponForm.vue'
import CouponsFilters from './CouponsFilters.vue'

const fields =
  'results{id,created_at,status,scope,scheme,amount,amount_formated,amount_type,usage_scheme,validity_limit,public_id,label,description,attributes},meta'

export default {
  components: {
    BCard,
    CouponsFilters,
    CouponForm,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      coupons: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 25,
        scheme: 'COUPON',
        term: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/coupons/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields': fields,
        },
      })
      this.coupons = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(item) {
      this.displayForm = false
      this.$router.push(`/coupons/${item.id}/`)
    },
  },
}
</script>
